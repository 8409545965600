<script>
    import Input from "@components/Input.svelte";
    import TimeInput from "@components/TimeInput.svelte";
    import Button from "@components/Button.svelte";
    import Modal from "@components/Modal.svelte";
    import dayjs from "@utils";
    import api from "@api";
    import { timeEntries } from "@store";

    let { entry = $bindable(null), show = $bindable(false) } = $props();

    const onconfirm = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        
        const newEntry = {
            task_title: formData.get("title"),
        };

        // Set the start time
        const [hours, minutes] = formData.get("start_time").split(":");
        newEntry.start_time = dayjs(entry.start_time).hour(hours).minute(minutes).toISOString();

        // Set the end time
        const [endHours, endMinutes] = formData.get("end_time").split(":");
        newEntry.end_time = dayjs(entry.end_time).hour(endHours).minute(endMinutes).toISOString();

        const [status, data] = await api.editEntry(entry.client_id, newEntry);

        if (status.ok) {
            $timeEntries = $timeEntries.map((entry) => {
                if (entry.client_id === data.client_id) {
                    return data;
                }
                return entry;
            });
        } else {
            console.error("Failed to edit entry", status, data);
        }

        show = false;
        return;
    };

    const handleDelete = async () => {
        if(confirm("Are you sure you want to delete this entry?")) {
            const [status, data] = await api.deleteEntries([entry.client_id]);

            if (status.ok) {
                $timeEntries = $timeEntries.filter((e) => e.client_id !== entry.client_id);
            } else {
                console.error("Failed to delete entry", status, data);
            }

            show = false;
        }   
        return
    }
</script>

<Modal bind:show {onconfirm}>
    <div class="flex-col gap-s">
        <h2>Edit Entry</h2>
        <label class="flex-col">
            <span>Title</span>
            <Input bind:value={entry.task_title} name="title" />
        </label>
        <div class="times">
            <label class="flex-col">
                <span>Start</span>
                <TimeInput value={dayjs(entry.start_time)} name="start_time" />
            </label>
            <label class="flex-col">
                <span>End</span>
                <TimeInput value={dayjs(entry.end_time)} name="end_time" />
            </label>
        </div>
    </div>

    {#snippet footer()}
        <div class="footer">
            <div class="first">
                <Button variant="danger" type="button" onclick={handleDelete}>Delete</Button>
            </div>
            <div class="last">
                <Button variant="secondary" type="button" onclick={() => show = false}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </div>
        </div>
    {/snippet}
</Modal>


<style lang="scss">
    .times {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: var(--zeit-space-100);

        label {
            width: min-content;
        }

        label:last-child {
            text-align: right;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: var(--zeit-space-100);
    }
</style>