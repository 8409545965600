<script>
    import Card from "@components/Card.svelte";
    import Progress from "@components/Progress.svelte";
    import { sessionEntries } from "@store";
    import dayjs from "dayjs";
    import Icon from "@iconify/svelte";

    let sessionsToday = $derived($sessionEntries.filter((session) => {
        return dayjs(session.starte_time).isSame(dayjs(), "day");
    }));

    let firstStartTime = $derived(dayjs(sessionsToday[0]?.start_time));
    let now = dayjs();
    let exptectedDepartureTime = $derived(dayjs(firstStartTime).add(8, "hours"));
</script>

<Card --area="session">
    <div class="session">
        <div class="labels subtle">
            <span>Arrived</span>
            <span>Leaving</span>
        </div>
        <Progress min={firstStartTime.valueOf()} max={exptectedDepartureTime.valueOf()} value={now.valueOf()} />
        <div class="times">
            <span>{firstStartTime.format("HH:mm")}</span>
            <span>{exptectedDepartureTime.format("HH:mm")}</span>
        </div>
    </div>
</Card>

<style lang="scss">
    .session {
        grid-area: session;
        display: flex;
        flex-direction: column;
        gap: var(--zeit-space-075);
    }

    .labels {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
    }

    .times {
        display: flex;
        justify-content: space-between;
        font-family: var(--zeit-mono);
        font-size: var(--zeit-font-size-xl);
        font-weight: 800;
    }
</style>
