<script>
    import { run, preventDefault } from 'svelte/legacy';

    import { fade, fly, scale } from "svelte/transition";
    import { createEventDispatcher } from "svelte";

    const dispach = createEventDispatcher();

    /**
     * @typedef {Object} Props
     * @property {boolean} [show]
     * @property {import('svelte').Snippet} [children]
     * @property {import('svelte').Snippet} [footer]
     */

    /** @type {Props} */
    let { show = $bindable(false), children, footer, onconfirm } = $props();


    const disableScroll = (disable) => {
        if (disable) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return
    }

    $effect(() => {
        if (show) {
            disableScroll(true);
        } else {
            disableScroll(false);
        }
    });
</script>

{#if show}
    <div class="modal__backdrop" transition:fade={{duration: 100}} onclick={(e) => {
        if (e.target === e.currentTarget) {
            show = false;
        }
    }}>
    </div>
    <form onsubmit={preventDefault((e) => onconfirm(e))} class="modal" transition:scale={{duration: 400, start: 0.5}}>
        <div class="modal__content">
            {@render children?.()}
        </div>
        <div class="modal__footer">
            {@render footer?.()}
        </div>
    </form>
{/if}

<style lang="scss">
    .modal__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;
        backdrop-filter: blur(2px);
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 25rem;
        max-width: 100%;
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        gap: var(--zeit-space-100);
        transform: translate(-50%, -50%);
        background-color: var(--zeit-color-gamma-050);
        border-radius: var(--zeit-radius-medium);
        border: 1px solid var(--zeit-color-gamma-100);
        padding: var(--zeit-space-150);
        z-index: 1001;
        box-shadow: var(--zeit-shadow-small);

        &__content {
            flex: 1;
        }

        &__footer {
            margin-top: var(--zeit-space-100);
            display: flex;
            justify-content: flex-end;
            gap: var(--zeit-space-100);
        }
    }
</style>