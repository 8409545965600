<script>
    import { onMount, onDestroy } from "svelte";

    import Button from "@components/Button.svelte";
    import Card from "@components/Card.svelte";
    import Input from "@components/Input.svelte";
    import Tooltip from "@components/Tooltip.svelte";
    import { timeEntries, sessionEntries } from "@store";
    import dayjs, { formatDuration } from "@utils";

    import Icon from "@iconify/svelte";
    import api from "@api";
    import Checkbox from "@components/Checkbox.svelte";

    let ongoingTimeEntry = $derived($timeEntries.find((timeEntry) => timeEntry.end_time === null));
    let options = $state([]);
    let valueText = $state("");
    let duration = $state("00:00:00");
    let wrapTimeToggle = $state(false);

    $effect(() => {
        if (wrapTimeToggle || ongoingTimeEntry) {
            startDuration();
        } else {
            stopDuration();
        }
    });

    const handleSearch = async (value) => {
        const [_, data] = await api.getEntryList({ search: value });
        options = [...new Set(data.items.map((item) => item.task_title))];
        return;
    };

    const startTimer = async () => {
        let entry = { task_title: valueText };
        if (wrapTimeToggle) {
            entry = { ...entry, start_time: $timeEntries[0]?.end_time ?? $sessionEntries[0].start_time };
        }
        const [status, data] = await api.createEntry(entry);
        if (status.ok) {
            $timeEntries = [data, ...$timeEntries];
        }
        return;
    };

    const getLatestTime = () => {
        const latestEntry = $timeEntries[0];
        const latestSession = $sessionEntries[0];
        if (latestEntry && latestSession) {
            return latestEntry.end_time > latestSession.start_time ? latestEntry.end_time : latestSession.start_time;
        } else if (latestEntry) {
            return latestEntry.end_time;
        } else if (latestSession) {
            return latestSession.start_time;
        } else {
            return dayjs();
        }
    };

    async function refreshTimer() {
        if (ongoingTimeEntry) {
            duration = formatDuration(ongoingTimeEntry.start_time, ongoingTimeEntry.end_time || dayjs(), "HH:mm:ss");
        } else {
            duration = formatDuration(getLatestTime(), dayjs(), "HH:mm:ss");
        }
    }

    let interval;

    const startDuration = () => {
        interval = setInterval(refreshTimer, 500);
        return;
    };

    const stopDuration = () => {
        clearInterval(interval);
        duration = "00:00:00";
        return;
    };

    async function handleStop() {
        await api.stopEntry();
    }
</script>

<Card --area="timer">
    <div class="timer">
        <div class="info">
            <div class="duration__container">
                {#if !ongoingTimeEntry}
                    <Tooltip>
                        <Button
                            --size="28px"
                            onclick={() => (wrapTimeToggle = !wrapTimeToggle)}
                            variant="icon {wrapTimeToggle ? 'primary' : ''}"
                        >
                            <Icon icon="flowbite:arrow-right-to-bracket-outline" />
                        </Button>
                        {#snippet tooltip()}
                            When enabled, the timer will start from the end of the last time entry or the start of the
                            current session.
                        {/snippet}
                    </Tooltip>
                {/if}
                <div class="duration">
                    {duration}
                </div>
            </div>
            {#if ongoingTimeEntry}
                <div class="title">{ongoingTimeEntry.task_title}</div>
            {:else}
                <Input
                    type="text"
                    placeholder="Task..."
                    search
                    onchange={(event) => (valueText = event.target.value)}
                    onkeyup={(event) => {
                        if (event.key === "Enter") {
                            startTimer();
                        }
                    }}
                    bind:options
                    searchCallback={handleSearch}
                />
            {/if}
        </div>
        <div class="controls">
            {#if ongoingTimeEntry}
                <Button variant="icon danger" onclick={handleStop}>
                    <Icon icon="fe:stop" />
                </Button>
            {:else}
                <Button variant="icon primary" onclick={startTimer}>
                    <Icon icon="fe:play" />
                </Button>
            {/if}
        </div>
    </div>
</Card>

<style lang="scss">
    .timer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: var(--zeit-line-height-compact);
        gap: var(--zeit-space-100);
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: var(--zeit-space-050);
        flex: 1;
    }

    .duration__container {
        display: flex;
        align-items: center;
        gap: var(--zeit-space-100);
    }

    .duration {
        font-family: var(--zeit-mono);
        font-size: var(--zeit-font-size-l);
        font-weight: 800;
        color: var(--zeit-color-text-subtle);
    }

    .title {
        font-size: var(--zeit-font-size-xxl);
        font-weight: 600;
    }

    .controls {
        display: flex;
        gap: 1rem;
    }
</style>
