<script>
    import { autoUpdate, offset, flip, computePosition, size } from "@floating-ui/dom";
    import { tick } from "svelte";
    import { debounce } from "@utils";
    import Icon from "@iconify/svelte";

    let { search = false, type = "text", searchCallback, options = [], emptyText = "No suggestions found...", ...rest } = $props();

    let loading = $state(false);

    let element = $state();
    let optionsElement = $state();
    let showOptions = $state(false);
    let hoverIndex = $state(-1);

    $effect(() => {
        options;
        loading = false;
    });

    const placeOptions = () => {
        computePosition(element, optionsElement, {
            placement: "bottom-start",
            middleware: [
                offset(8),
                flip(),
                size({
                    apply({ rects, availableWidth, availableHeight, elements }) {
                        Object.assign(elements.floating.style, {
                            maxWidth: `${Math.max(0, availableWidth)}px`,
                            maxHeight: `${Math.max(0, availableHeight)}px`,
                            minWidth: `${rects.reference.width}px`,
                        });
                    },
                }),
            ],
        }).then(({ x, y }) => {
            Object.assign(optionsElement.style, {
                top: `${y}px`,
                left: `${x}px`,
            });
        });
        return;
    };

    const debouncedSearch = debounce((value) => {
        if (searchCallback && value) searchCallback(value);
    }, 400);

    const handleSearch = async (event) => {
        if (!search) return;

        const value = event.target.value;
        loading = true;

        debouncedSearch(value);

        if (!showOptions && value) {
            showOptions = true;

            await tick();

            placeOptions();
        } else if (!value) {
            showOptions = false;
        }
        return;
    };

    const handleKeydown = async (event) => {
        if (!search) return;

        if (event.key === "ArrowDown") {
            event.preventDefault();
            hoverIndex = Math.min(hoverIndex + 1, options.length - 1);
            return;
        }

        if (event.key === "ArrowUp") {
            event.preventDefault();
            hoverIndex = Math.max(hoverIndex - 1, -1);
            return;
        }

        if (event.key === "Enter") {
            const value = options[hoverIndex];
            if (!value) return;
            element.value = value;
            showOptions = false;
            return;
        }

        if (event.key === "Escape") {
            showOptions = false;
            element.blur();
            return;
        }

        return;
    };

    const handleBlur = (event) => {
        if (!search) return;

        // Hide the search options when the input loses focus
        showOptions = false;
        return;
    };

    const handleSelect = (event) => {
        const value = event.target.innerText;
        element.value = value;
        showOptions = false;
        return;
    };
</script>

<!-- svelte-ignore a11y_role_has_required_aria_props -->
<!-- svelte-ignore a11y_interactive_supports_focus -->
<div class="container" onkeydown={handleKeydown} role="combobox">
    <input bind:this={element} type={type} {...rest} onblur={handleBlur} oninput={handleSearch} />
    {#if search && showOptions}
        <div bind:this={optionsElement} class="options">
            {#if loading}
                <div class="option disabled flex-center">
                    <Icon icon="svg-spinners:ring-resize" />
                </div>
            {:else}
                {#each options as option, index}
                    <!-- svelte-ignore a11y_click_events_have_key_events -->
                    <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
                    <div class="option" class:hover={index === hoverIndex} onclick={handleSelect} role="listitem">
                        {option}
                    </div>
                {:else}
                    <div class="option disabled">{emptyText}</div>
                {/each}
            {/if}
        </div>
    {/if}
</div>

<style lang="scss">
    .container {
        position: relative;
        width: 100%;
    }

    .options {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--zeit-color-gamma-100);
        border: 1px solid var(--zeit-color-gamma-200);
        border-radius: var(--zeit-radius-medium);
        padding: var(--zeit-space-025);
        overflow-y: auto;
        z-index: 1000;
    }

    .option {
        padding: var(--zeit-space-050) var(--zeit-space-075);
        border-radius: var(--zeit-radius-small);
        cursor: pointer;

        &:hover {
            background: var(--zeit-color-gamma-200);
        }

        &.hover {
            outline: 1px solid var(--zeit-color-primary-tint);
            outline-offset: 2px;
        }

        &.disabled {
            color: var(--zeit-color-text-subtle);
        }
    }
</style>
