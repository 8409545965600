<script>
    let {
        checked = $bindable(),
        label,
        ...rest
    } = $props();

    const handleKeydown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            checked = !checked;
        }
    };
</script>

<label class="container">
    <input type="checkbox" class="toggle" bind:checked {...rest} >
    <div
        class="toggle__container"
        tabindex="0"
        role="switch"
        aria-checked={checked}
        onkeydown={handleKeydown}
    >
        <svg class="icons toggle__on" viewBox="0 0 24 24">
            <line x1="12" y1="8" x2="12" y2="16" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
        </svg>
        <div class="toggle__handle"></div>
        <svg class="icons toggle__off" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="4" fill="transparent" stroke="currentColor" stroke-width="2" />
        </svg>
    </div>
    {#if label}
        <span>{label}</span>
    {/if}
</label>

<style>
    .container {
        display: inline-flex;
        gap: var(--zeit-space-050);
        position: relative;
        cursor: pointer;
        user-select: none;
        align-items: center;
        flex-wrap: wrap-reverse;
        --size: 24px;
        
        &:focus-within .toggle__container {
            outline: 1px solid var(--zeit-color-primary);
            outline-offset: 2px;
        }
    }

    .toggle {
        display: none;
    }

    .toggle:checked + .toggle__container {
        background-color: var(--zeit-color-primary);
        color: var(--zeit-color-gamma-050);
    }

    .toggle__container {
        margin: 1px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: calc(var(--size) * 2);
        height: var(--size);
        flex: 0 0 auto;
        padding: 2px;
        outline: 1px solid var(--zeit-color-gamma-300);
        background-color: var(--zeit-color-gamma-100);
        color: inherit;
        border-radius: var(--zeit-radius-small);
        transition:
            background-color 0.2s,
            border-color 0.2s;
    }

    .toggle__container:hover {
        background-color: var(--zeit-color-gamma-200);
    }

    .toggle__container .toggle__handle {
        position: absolute;
        content: "";
        display: inline-block;
        width: calc(var(--size) - 2px);
        height: calc(var(--size) - 4px);
        border-radius: var(--zeit-radius-small);
        background-color: var(--zeit-color-gamma-600);
        transition: transform 0.2s;
    }

    .toggle:checked + .toggle__container .toggle__handle {
        transform: translateX(calc(var(--size) - 2px));
        background-color: var(--zeit-color-gamma-050);
    }
</style>