<script>
    import Card from "@components/Card.svelte";
    import Button from "@components/Button.svelte";
    import Icon from "@iconify/svelte";
    import dayjs from "@utils";
    import { timeEntries } from "@store";
    import { onMount } from "svelte";

    let {
        day = $bindable(dayjs()),
    } = $props();

    let daysElement = $state();
    const startOfWeek = day.startOf("week");

    const getHoursForDay = async (day) => {
        const entries = $timeEntries.filter(
            (entry) => dayjs(entry.start_time).isSame(day, "day") && entry.end_time !== null
        );
        const totalHours = entries.reduce(
            (acc, entry) => acc + dayjs(entry.end_time).diff(dayjs(entry.start_time), "hour", true),
            0
        );
        const roundedTotalHours = parseFloat(totalHours.toFixed(1));
        return roundedTotalHours;
    };

    onMount(() => {
        // Scroll to the current day
        daysElement.querySelector(".today")?.scrollIntoView({
            behavior: "instant",
            block: "center",
            inline: "center",
        });
    });
</script>

<Card --area="weekly" outlined>
    <div class="days" bind:this={daysElement}>
        {#each Array(5) as _, index}
            {@const day = startOfWeek.add(index, "day")}
            <button
                class="day__container"
                class:inFuture={day.isAfter(dayjs())}
                class:today={day.isSame(dayjs(), "day")}
            >
                <div class="day">
                    <span class="name">{day.format("ddd")}</span>
                    <span class="date">{day.format("DD")}</span>
                </div>
                <div class="hours">
                    {#if !day.isAfter(dayjs())}
                        {#await getHoursForDay(day)}
                            <span>--</span>
                        {:then hours}
                            <span>{hours}</span>
                        {/await}
                    {:else}
                        <span>--</span>
                    {/if}
                </div>
            </button>
        {/each}
    </div>
</Card>

<style lang="scss">
    .days {
        display: flex;
        gap: var(--zeit-space-075);
        width: 100%;
        justify-content: space-between;
        overflow-x: auto;
    }

    .day__container {
        all: unset;
        pointer-events: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--zeit-space-025);
        padding: var(--zeit-space-050);
        border-radius: var(--zeit-radius-small);
        width: 100%;

        &.action {
            width: 2rem;
            flex: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--zeit-font-size-xl);
            color: var(--zeit-color-text-subtle);
        }

        &.inFuture {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &.today .hours {
            text-decoration: underline;
        }

        &:last-child {
            border-right: none;
        }

        &:hover {
            background-color: var(--zeit-color-gamma-200);
        }

        .hours {
            font-size: var(--zeit-font-size-xl);
            font-weight: 900;
            color: var(--zeit-color-text-strong);
            font-family: var(--zeit-mono);
        }
    }

    .day {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--zeit-space-025);

        .name {
            font-size: var(--zeit-font-size-s);
            font-weight: 600;
            color: var(--zeit-color-text-strong);
        }

        .date {
            font-size: var(--zeit-font-size-s);
            font-weight: 400;
            color: var(--zeit-color-text-subtle);
        }
    }
</style>
