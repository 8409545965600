<script>
    import Card from "@components/Card.svelte";
    import { timeEntries } from "@store";
    import dayjs, { formatDuration } from "@utils";

    let entriesToday = $derived(
        $timeEntries.filter((entry) => dayjs(entry.start_time).isSame(dayjs(), "day"))
    );

    const getHoursToday = async () => {
        let totalHours = 0;
        for (let entry of entriesToday) {
            if (entry.end_time) {
                totalHours += dayjs(entry.end_time).diff(dayjs(entry.start_time), "hour", true);
            }
        }
        return totalHours;
    }
</script>

<Card --area="hours-today">
    <h2>Hours Today</h2>
    <div class="hours">
        {#key entriesToday}
            {#await getHoursToday()}
                <span>--</span>
            {:then hours}
                <span>{hours.toFixed(1)}</span>
            {/await}
        {/key}
    </div>
</Card>

<style>
    h2 {
        font-size: var(--zeit-font-size-n);
        color: var(--zeit-color-text-subtle);
        font-weight: 500;
    }
    .hours {
        margin-top: var(--zeit-space-050);
        display: grid;
        gap: var(--zeit-space-050);
        font-family: var(--zeit-mono);
        font-size: var(--zeit-font-size-xxl);
        font-weight: 800;
    }
</style>
