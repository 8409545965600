<script>
    const { variant = "primary", children, ...rest} = $props();
</script>

<button class={variant} {...rest}>
    {@render children?.()}
</button>

<style lang="scss">
    button {
        font-family: inherit;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: var(--zeit-space-050) var(--zeit-space-075);
        font-weight: 500;
        color: var(--zeit-color-gamma-050);
        border: none;
        border-radius: var(--zeit-radius-medium);
        cursor: pointer;
        transition:
            color 0.1s,
            background-color 0.1s,
            border-color 0.1s;

        &:disabled {
            background-color: var(--color-gray-dkr);
            color: var(--color-darkgray);
            border-color: var(--color-darkgray);
            cursor: not-allowed;
        }

        > * {
            pointer-events: none;
            min-width: 1rem;
            min-height: 1rem;
        }
    }

    button.icon {
        background-color: transparent;
        color: var(--zeit-color-text-regular);
        padding: var(--zeit-space-050);
        aspect-ratio: 1 / 1;

        width: var(--size, 36px);
        height: var(--size, 36px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.primary {
        background-color: var(--zeit-color-primary);
        color: var(--zeit-color-gamma-050);

        &:hover {
            background-color: var(--zeit-color-primary-tint);
        }

        &:active {
            background-color: var(--zeit-color-primary-shade);
        }
    }

    button.secondary {
        background-color: var(--zeit-color-gamma-200);
        color: var(--zeit-color-text-regular);

        &:hover {
            background-color: var(--zeit-color-gamma-150);
        }

        &:active {
            background-color: var(--zeit-color-gamma-350);
        }
    }

    button.danger {
        background-color: var(--zeit-color-util-alert);
        color: var(--zeit-color-gamma-050);
        border-color: var(--color-danger-dk);

        &:hover {
            background-color: var(--zeit-color-util-alert-tint);
        }

        &:active {
            background-color: var(--zeit-color-util-alert-shade);
        }
    }

    button.tertiary {
        background-color: transparent;
        color: var(--zeit-color-text-regular);
        border: 1px solid var(--zeit-color-text-regular);

        &:hover {
            color: (--zeit-color-gamma-950);
        }

        &.active {
            color: var(--zeit-color-gamma-750);
        }
    }


</style>
