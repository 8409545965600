<script>
    import { tick } from "svelte";
    import dayjs from "@utils";

    // check if on ios
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    /**
     * @typedef {Object} Props
     * @property {dayjs} value
     */

    /** @type {Props} */
    let { value = $bindable(), ...rest } = $props();

    let textElement = $state();

    const onTextChange = (event) => {
        value = dayjs(event.target.value, "HH:mm");
        value = value.isValid() ? value : dayjs();
        return;
    };

    const onChange = (event) => {
        const [hours, minutes] = event.target.value.split(":");
        value = dayjs(value).hour(hours).minute(minutes);
        return;
    };

    const handleKeyDown = async (event) => {
        // Arrow keys increment/decrement time
        const cursorPosition = textElement.selectionStart;
        if (event.key === "ArrowUp") {
            event.preventDefault();
            // get where the cursor is to increment the correct part of the time
            if (cursorPosition < 3) {
                value = dayjs(value).add(1, "hour");
            } else {
                value = dayjs(value).add(event.shiftKey ? 5 : 1, "minute");
            }
        } else if (event.key === "ArrowDown") {
            event.preventDefault();
            if (cursorPosition < 3) {
                value = dayjs(value).subtract(1, "hour");
            } else {
                value = dayjs(value).subtract(event.shiftKey ? 5 : 1, "minute");
            }
        } else {
            return;
        }
        await tick();
        // set the cursor back to where it was
        textElement.selectionStart = cursorPosition;
        textElement.selectionEnd = cursorPosition;
    };
</script>

{#if isIOS}
    <input type="time" onchange={onChange} value={value.format("HH:MM")} {...rest} />
{:else}
    <input
        type="text"
        bind:this={textElement}
        onkeydown={handleKeyDown}
        onchange={onTextChange}
        value={value.format("HH:mm")}
        {...rest}
    />
{/if}


<style lang="scss">
    input {
        min-width: 4.2rem;
        text-align: center;
    }
</style>