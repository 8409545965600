<script>
    import { timeEntries, sessionEntries } from "@store";
    import api from "@api";
    import Session from "pages/widgets/Session.svelte";
    import Timer from "pages/widgets/Timer.svelte";
    import Card from "@components/Card.svelte";
    import Weekly from "pages/widgets/Weekly.svelte";
    import dayjs from "@utils";
    import Entries from "pages/widgets/Entries.svelte";
    import Lunch from "pages/widgets/Lunch.svelte";
    import HoursToday from "pages/widgets/HoursToday.svelte";
    import { connectWebSocket } from "@lib/ws.js";
    import { onMount, onDestroy } from "svelte";

    let today = $state(dayjs());

    const getTimeEntries = async () => {
        const [_, data] = await api.getEntryList({
            from_date: today.startOf("week").format(),
            to_date: today.endOf("week").format(),
        });
        $timeEntries = data.items;
        return;
    };

    const getSessionEntries = async () => {
        const [_, data] = await api.getSessionList();
        $sessionEntries = data.items;
        return;
    };

    const getData = async () => {
        await Promise.allSettled([getTimeEntries(), getSessionEntries()]);
        return;
    };


    onMount(() => {
        connectWebSocket();
    });

    onDestroy(() => {
        if (socket) {
            socket.close();
        }
    });
</script>

<div class="grid">
    {#key today}
        {#await getData()}
            <Card ghost --area="weekly" />
            <Card ghost --area="timer" />
            <Card ghost --area="lunch" />
            <Card ghost --area="hours-today" />
            <Card ghost --area="session" />
            <Card ghost --area="entries" />
        {:then _}
            <Weekly />
            <Timer />
            <Lunch />
            <HoursToday />
            <Session />
            <Entries />
        {/await}
    {/key}
</div>

<style lang="scss">
    .grid {
        display: grid;
        grid-template-areas:
            "weekly weekly weekly weekly"
            "timer timer lunch hours-today"
            "session session entries entries";
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(auto, minmax(100px, 1fr));
        min-height: 400px;
        gap: 1rem;
        padding: 1rem;
    }

    @media (max-width: 600px) {
        .grid {
            grid-template-areas:
                "weekly weekly"
                "timer timer"
                "lunch hours-today"
                "session session"
                "entries entries";
            grid-template-columns: 1fr 1fr;
        }
    }
</style>
