<script>
    import { shift, offset, flip, computePosition, size, autoUpdate, arrow } from "@floating-ui/dom";

    let { children, tooltip, placement = "top", ...rest } = $props();

    let trigger = $state();
    let tooltipElement = $state();
    let showTooltip = $state(false);
    let autoUpdateStave = $state();
    let arrowEl = $state();

    $effect(() => {
        if (showTooltip) {
            placeTooltip();
            autoUpdateStave = autoUpdate(trigger, tooltipElement, placeTooltip);
        } else {
            autoUpdateStave?.();
        }
    });

    const placeTooltip = () => {
        computePosition(trigger, tooltipElement, {
            placement: placement,
            middleware: [
                offset(12),
                flip(),
                shift(),
                size({
                    apply({ availableWidth, availableHeight, elements }) {
                        Object.assign(elements.floating.style, {
                            width: `${Math.min(300, availableWidth)}px`,
                            maxHeight: `${Math.max(0, availableHeight)}px`,
                        });
                    },
                }),
                arrow({element: arrowEl}),
            ],
        }).then(({ x, y, middlewareData, placement }) => {
            Object.assign(tooltipElement.style, {
                top: `${y}px`,
                left: `${x}px`,
            });

            const arrowData = middlewareData.arrow;

            arrowEl.dataset.placement = placement;

            Object.assign(arrowEl.style, {
                left: arrowData.x != null ? `${arrowData.x}px` : "",
                top: arrowData.y != null ? `${arrowData.y}px` : "",
            });
        });
        return;
    };

    const handleFocus = (event) => {
        showTooltip = true;
        return
    }
</script>

<div class="tooltip__container">
    <div
        class="tooltip__trigger"
        bind:this={trigger}
        onpointerenter={() => (showTooltip = true)}
        onpointerleave={() => (showTooltip = false)}
        onfocusin={() => (showTooltip = true)}
        onfocusout={() => (showTooltip = false)}
    >
        {@render children?.()}
    </div>
    <div class="tooltip__content" class:showTooltip bind:this={tooltipElement}>
        {@render tooltip?.()}
        <div id="arrow" bind:this={arrowEl}></div>
    </div>
</div>

<style>
    .tooltip__container {
        display: inline-block;
        position: relative;
    }

    .tooltip__content {
        display: none;
        position: absolute;
        z-index: 1000;
        background-color: var(--zeit-color-gamma-050);
        color: var(--zeit-color-text-regular);
        border-radius: var(--zeit-radius-small);
        font-size: var(--zeit-font-size-s);
        border: 1px solid var(--zeit-color-gamma-500);
        padding: var(--zeit-space-050) var(--zeit-space-075);
        box-shadow: var(--zeit-shadow-small);

        &.showTooltip {
            display: block;
        }
    }

    .tooltip__trigger {
        display: inline-block;
    }

    #arrow {
        position: absolute;
        width: 16px;
        height: 16px;
        content: "";
        rotate: 45deg;
        background-color: var(--zeit-color-gamma-050);
        border: 1px solid var(--zeit-color-gamma-500);
        z-index: 1001;

        :global(&[data-placement="top"]) {
            border-left: none;
            border-top: none;
        }

        :global(&[data-placement="right"]) {
            border-top: none;
            border-right: none;
        }

        :global(&[data-placement="bottom"]) {
            border-left: none;
            border-bottom: none;
        }

        :global(&[data-placement="left"]) {
            border-top: none;
            border-left: none;
        }

    }
</style>
