const baseURL = "/api/v1";

const getCSRFToken = () => {
    const csrfToken = document.querySelector('[name="csrfmiddlewaretoken"]')?.value;
    return csrfToken;
};

const setCSRFToken = () => {
    const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": getCSRFToken(),
    };
    return headers;
};

const api = {
    getSessionList: async (options = {}) => {
        const params = new URLSearchParams(options);
        const response = await fetch(`${baseURL}/tracking/session?${params}`);
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    createSession: async (sessionData) => {
        const response = await fetch(`${baseURL}/tracking/session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(sessionData),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    editSession: async (id, sessionData) => {
        const response = await fetch(`${baseURL}/tracking/session/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(sessionData),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    deleteSessions: async (ids) => {
        const response = await fetch(`${baseURL}/tracking/session`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(ids),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    getCurrentSession: async () => {
        const response = await fetch(`${baseURL}/tracking/session/current`);
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    startSession: async (options) => {
        const params = new URLSearchParams(options);
        const response = await fetch(`${baseURL}/tracking/session/start?${params}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    endSession: async () => {
        const response = await fetch(`${baseURL}/tracking/session/end`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    getEntryList: async (options = {}) => {
        const params = new URLSearchParams(options);
        const response = await fetch(`${baseURL}/tracking/entry?${params}`);
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    createEntry: async (entryData) => {
        const response = await fetch(`${baseURL}/tracking/entry`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(entryData),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    editEntry: async (id, entryData) => {
        const response = await fetch(`${baseURL}/tracking/entry/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(entryData),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    deleteEntries: async (ids) => {
        const response = await fetch(`${baseURL}/tracking/entry`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
            body: JSON.stringify(ids),
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    getCurrentEntry: async () => {
        const response = await fetch(`${baseURL}/tracking/entry/current`);
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    startEntry: async (options = {}) => {
        const params = new URLSearchParams(options);
        const response = await fetch(`${baseURL}/tracking/entry/start?${params}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
    stopEntry: async () => {
        const response = await fetch(`${baseURL}/tracking/entry/stop`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken(),
            },
        });
        return [{ ok: response.ok, status: response.status }, await response.json()];
    },
};

export default api;
