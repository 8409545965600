import { timeEntries, sessionEntries } from "./store.svelte.js";

let socket;
let reconnectInterval = 5000; // 5 seconds

const connectWebSocket = () => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    socket = new WebSocket(`${protocol}://${window.location.host}/ws/tracking/`);

    socket.onopen = () => {
        console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "time_entry.update") {
            const data_entry = JSON.parse(data.message);
            timeEntries.update((entries) => {
                const index = entries.findIndex((entry) => entry.client_id === data_entry.client_id);
                if (index !== -1) {
                    return entries.map((entry) => (entry.client_id === data_entry.client_id ? data_entry : entry));
                } else {
                    return [data_entry, ...entries];
                }
            });
        }

        if (data.type === "session.update") {
            const data_entry = JSON.parse(data.message);
            sessionEntries.update((entries) => {
                const index = entries.findIndex((entry) => entry.client_id === data_entry.client_id);
                if (index !== -1) {
                    // Create a new array with the updated entry
                    return entries.map((entry) => (entry.client_id === data_entry.client_id ? data_entry : entry));
                } else {
                    return [data_entry, ...entries];
                }
            });
        }

        if (data.type === "time_entry.delete") {
            const data_entry = JSON.parse(data.message);
            timeEntries.update((entries) => {
                return entries.filter((entry) => entry.client_id !== data_entry.client_id);
            });
        }

        if (data.type === "session.delete") {
            const data_entry = JSON.parse(data.message);
            sessionEntries.update((entries) => {
                return entries.filter((entry) => entry.client_id !== data_entry.client_id);
            });
        }
    };

    socket.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        setTimeout(connectWebSocket, reconnectInterval);
    };

    socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        socket.close();
    };
};

export { connectWebSocket };
