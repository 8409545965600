<script>
    let {
        min,
        max,
        step,
        color = "var(--zeit-color-primary)",
        value
    } = $props();

    let percentageDone = $derived(((value - min) / (max - min)) * 100)
</script>

<div class="progress">
    <div class="progress-bar" style="--color: {color}; --progress: {percentageDone}%"></div>
</div>

<style>
    .progress {
        width: 100%;
        height: 1rem;
        background-color: var(--zeit-color-gamma-250);
        border-radius: 0.25rem;
        overflow: hidden;
    }

    .progress-bar {
        width: var(--progress);
        height: 100%;
        background-color: var(--color);
    }
</style>