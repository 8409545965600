<script>
    let { children, outlined, ghost = $bindable(false), ...rest } = $props();
</script>

<div class="card" class:ghost class:outlined {...rest}>
    {#if children}
        {@render children?.()}
    {/if}
</div>

<style lang="scss">
    .card {
        grid-area: var(--area);
        background-color: var(--zeit-color-gamma-100);
        border-radius: 0.5rem;
        padding: 1rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

        &.outlined {
            border: 1px solid var(--zeit-color-gamma-200);
            background-color: transparent;
        }
    }

    .ghost {
        background: var(--zeit-color-gamma-200);
        box-shadow: none;
        animation: animate 0.8s ease-in-out infinite alternate;
    }

    @keyframes animate {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
</style>