<script>
    import Card from "@components/Card.svelte";
    import Toggle from "@components/Toggle.svelte";
    import api from "@api";
    import { timeEntries } from "@store";
    import dayjs from "@utils";

    let autoLunch = $state(false);

    // Interval to check if it's time for lunch
    let interval = null;

    // Check if it's time for lunch
    const checkLunch = async () => {
        const now = dayjs();
        const hours = now.hour();
        const minutes = now.minute();

        if (hours !== 21 && minutes !== 29) return;

        // Check if ongoing time entry
        const ongoingTimeEntry = $timeEntries.find((timeEntry) => timeEntry.end_time === null);

        if (!ongoingTimeEntry) return;

        // Stop the ongoing time entry
        const stopTimeEntry = async () => {
            const [status, data] = await api.stopEntry(ongoingTimeEntry.client_id);

            if (status.ok) {
                $timeEntries = $timeEntries.map((entry) => {
                    if (entry.client_id === data.client_id) {
                        return data;
                    }
                    return entry;
                });
            } else {
                console.error("Failed to stop entry", status, data);
            }
        };

        stopTimeEntry();
    };

    // Start the interval
    const startInterval = () => {
        interval = setInterval(checkLunch, 1000 * 60);
    };

    // Stop the interval
    const stopInterval = () => {
        clearInterval(interval);
    };

    // Start the interval if autoLunch is enabled
    $effect(() => {
        if (autoLunch) {
            startInterval();
        } else {
            stopInterval();
        }
    });
</script>

<Card --area="lunch">
    <div class="lunch">
        <Toggle bind:checked={autoLunch} label="Auto Lunch" />
        <p>Stop the timer at 11:00.</p>
    </div>
</Card>

<style>

    .lunch {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: var(--zeit-space-050);
        height: 100%;
    }
    p {
        font-size: var(--zeit-font-size-s);
        color: var(--zeit-color-text-subtle);
    }
</style>
