<script>
    import Button from "@components/Button.svelte";
    import Icon from "@iconify/svelte";
    import Card from "@components/Card.svelte";
    import Input from "@components/Input.svelte";
    import { timeEntries } from "@store";
    import dayjs, { formatDuration } from "@utils";
    import api from "@api";
    import Modal from "@components/Modal.svelte";
    import EditEntry from "lib/modules/EditEntry.svelte";

    let entriesToday = $derived($timeEntries.filter((entry) => dayjs(entry.start_time).isSame(dayjs(), "day")));

    const startEntry = async (entry_id) => {
        const entry = $timeEntries.find((entry) => entry.client_id === entry_id);

        if (!entry) {
            console.error("Entry not found", entry_id);
            return;
        }

        let newEntry = {
            task_title: entry.task_title,
            start_time: dayjs().toISOString(),
        };

        const [status, data] = await api.createEntry(newEntry);

        if (status.ok) {
            $timeEntries = [data, ...$timeEntries];
        } else {
            console.error("Failed to start entry", status, data);
        }
        return;
    }

    let editingEntry = $state(null);
    let showEditEntry = $state(false);

    const handleEditEntry = (entry_id) => {
        editingEntry = $timeEntries.find((entry) => entry.client_id === entry_id);
        showEditEntry = true;
        return
    }
</script>

<EditEntry bind:entry={editingEntry} bind:show={showEditEntry} />

<Card --area="entries" style="grid-row: span 2" outlined>
    <h2>Entries Today ({entriesToday.length ?? 0})</h2>
    <div class="entries">
        {#each entriesToday.filter((entry) => entry.end_time) as entry}
            <div class="entry">
                <div class="entry__info">
                    <div class="duration">
                        {formatDuration(entry.start_time, entry.end_time)}
                    </div>
                    <div class="entry__name">
                        {entry.task_title}
                    </div>
                </div>
                <div class="entry__duration">
                </div>
                <div class="controls">
                    <Button variant="icon secondary" onclick={() => startEntry(entry.client_id)}>
                        <Icon icon="fe:play" />
                    </Button>
                    <Button variant="icon secondary" onclick={() => handleEditEntry(entry.client_id)}>
                        <Icon icon="fe:pencil" />
                    </Button>
                </div>
            </div>
        {/each}
    </div>
</Card>

<style lang="scss">
    .entries {
        display: flex;
        flex-direction: column;
        margin-top: var(--zeit-space-100);
        gap: var(--zeit-space-100);
        overflow: auto;

    }
    
    .entry {
        background-color: var(--zeit-color-gamma-100);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--zeit-space-050) var(--zeit-space-100);
        border-radius: var(--zeit-radius-medium);

        --size: 28px;

        .duration {
            font-family: var(--zeit-mono);
            font-size: var(--zeit-font-size-s);
            color: var(--zeit-color-text-subtle);
        }
        
        .controls {
            display: flex;
            gap: var(--zeit-space-050);
        }
        
    }
</style>